import { Link } from 'react-router-dom';
function ObstructiveSleepApneaFAQ() {
  return (
    <main className="page main-content gutters">
      <div className="section-header">Obstructive Sleep Apnea FAQ</div>
      {/* <div className="page-title">Obstructive Sleep Apnea FAQ</div> */}
      <div className="FAQ-subheader">
        What is obstructive sleep apnea (OSA)?
      </div>
      <p className="">
        <span className="accent-color">Obstructive sleep apnea</span> is a sleep
        disorder where breathing repeatedly stops and starts due to blocked
        airways.
      </p>
      <br />
      <div className="FAQ-subheader">How bad is OSA for my health?</div>
      Left untreated, OSA can have significant impacts on your health.
      <div className="FAQ-flex-box-container">
        <div className="FAQ-flex-box-caption-health-issues">
          <ul>
            <li>
              <span className="accent-color">Cardiovascular Problems:</span> OSA
              is linked to high blood pressure, heart disease, heart attacks,
              and strokes. The repeated interruptions in breathing cause stress
              on the cardiovascular system.
            </li>

            <li>
              <span className="accent-color">Daytime Fatigue:</span> OSA often
              leads to poor sleep quality, resulting in excessive daytime
              sleepiness, fatigue, and difficulty concentrating. This can affect
              your work performance and increase the risk of accidents.
            </li>

            <li>
              <span className="accent-color">Metabolic Disorders:</span> OSA is
              associated with an increased risk of type 2 diabetes and metabolic
              syndrome. The disruptions in sleep can affect insulin sensitivity
              and glucose metabolism.
            </li>

            <li>
              <span className="accent-color">Liver Problems:</span> OSA can
              contribute to abnormal liver function and nonalcoholic fatty liver
              disease.
            </li>

            <li>
              <span className="accent-color">Mental Health Issues:</span> Poor
              sleep quality and chronic fatigue can contribute to depression,
              anxiety, and other mental health issues.
            </li>

            <li>
              <span className="accent-color">Weight Gain:</span> OSA is often
              associated with weight gain and obesity. The fatigue and lack of
              energy can make it challenging to maintain a healthy lifestyle.
            </li>

            <li>
              <span className="accent-color">Decreased Immune Function:</span>{' '}
              Poor sleep can weaken the immune system, making you more
              susceptible to infections.
            </li>

            <li>
              <span className="accent-color">Increased Mortality Risk:</span>{' '}
              Severe untreated OSA has been linked to an increased risk of
              mortality due to its association with cardiovascular diseases and
              other health conditions.
            </li>
          </ul>
        </div>
        {/* <p className="FAQ-flex-box-caption-bottom">
          Studies have identified significant impacts to peoples health with
          untreated OSA.
        </p> */}
        <img
          className="FAQ-health-issues"
          src="/images/reference/OSA_health_impacts.png"
          alt="OSA loop cycle"
        />
      </div>
      <br />
      <div className="FAQ-subheader">How does it affect my daily life?</div>
      <p className="">
        Beyond the long-term health dangers, OSA can heavily affect your daily
        life if untreated.
        <ul>
          <li>Sleep deprivation</li>
          <li>Depression</li>
          <li>Anxiety</li>
          <li>Focus</li>
          <li>Mood swings</li>
          <li>Reduced libido and hormone imbalances</li>
          <li>Poor sleep quality</li>
          <li>Higher risk of accidents</li>
          <li>Excessive daytime sleepiness and exhaustion</li>
        </ul>
      </p>
      <br />
      {/* <div className="FAQ-subheader">How does it occur?</div> */}
      {/* <p className="">
        Most people are impacted the most when sleeping on their back.
      </p> */}
      {/* <div className="FAQ-flex-box-container">
        <p className="FAQ-flex-box-caption-top">
          <ol>
            <li>1. </li>
            <li>2. </li>
            <li>3.</li>
            <li>4.</li>
            <li>
              And the cylce repeats through the night without them knowing. upto
              a hundred times an hour
            </li>
          </ol>
        </p>
        <img
          className="FAQ-image"
          src="/images/reference/OSA_loop_cycle.jpg"
          alt="OSA loop cycle"
        />
      </div> */}
      <p>
        <div className="FAQ-subheader">
          When should someone see a doctor about obstructive sleep apnea?
        </div>
        <p className="">
          <ul>
            <li>Snoring loud enough to disturb your sleep or that of others</li>
            <li>Waking up gasping or choking</li>
            <li>Pausing in your breathing during sleep</li>
            <li>
              Having excessive daytime drowsiness, which may cause you to fall
              asleep while working, watching television, or even driving a
              vehicle
            </li>
          </ul>
        </p>
        <br />
        <div className="FAQ-subheader">
          How can positional therapy help sleep apnea?
        </div>
        <p className="">
          Studies have shown that positional therapy can reduce obstructive
          sleep apnea in a majority of OSA sufferers, by opening up the airways
          with side sleeping. This is the therapy SleepDev specializes in. You
          can read more about how positional therapy works on our{' '}
          <Link to="/PositionalTherapyFAQ">FAQ page</Link>.
        </p>
        {/* <br />
        <div className="FAQ-subheader">...</div>
        <p className="">
          +++
          <br />
          <ol>
            <li>...</li>
          </ol>
        </p>
        <br />
        <div className="FAQ-subheader">...</div>
        <p className="">
          ...
          <br />
          <ol>
            <li>...</li>
          </ol>
        </p> */}
        {/* <span className="emphasis bold">Sleep apnea</span> is a disorder
        characterized by repetitive pauses in breathing during sleep. These
        pauses can occur several times per hour and last for over 10 seconds,
        causing the sleeper to move out of a deep sleep and oftet wake up. There
        are three different types of sleep apnea, each with their own causes and
        symptoms. */}
      </p>
      {/* <p>
        It’s important to get an accurate diagnosis and effective treatment to
        prevent long-term sleep apnea risks.
      </p>
      <br /> */}
      {/* <p>
        <span className="bold warning">⚠️</span> Loud snoring can indicate a
        potentially serious problem, but not everyone who has sleep apnea
        snores. Talk to your doctor if you have signs or symptoms of sleep
        apnea. Ask your doctor about any sleep problem that leaves you fatigued,
        sleepy and irritable.
      </p> */}
      <br />
      <div className="accordion">
        {/* <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-one"
            className="accordion__input"
          />
          <label htmlFor="section-one" className="accordion__label">
            What are the different types of sleep apnea?
          </label>
          <div className="accordion__content">
            <div className="flexbox-container-top">
              <div className="flexbox-item"></div>
              <div className="flexbox-item"></div>
            </div>

            <div className="flex-box-container-OSA">
              <div className="flex-box-item-OSA-text">
                <ul>
                  <li>
                    <span className="emphasis bold">
                      Obstructive sleep apnea
                    </span>
                    , the more common form that occurs when throat muscles
                    relax. Affects around 25 million sufferers in the U.S alone.
                  </li>
                  <li>
                    <span className="emphasis bold">Central sleep apnea</span>,
                    which occurs when your brain doesn't send proper signals to
                    the muscles that control breathing
                  </li>
                  <li>
                    <span className="emphasis bold">
                      Complex sleep apnea syndrome
                    </span>
                    , also known as treatment-emergent central sleep apnea,
                    which occurs when someone has both obstructive sleep apnea
                    and central sleep apnea
                  </li>
                </ul>
                <br />
                <hr className="faded" />
                <br />
                <ul>
                  <li>
                    <span className="emphasis bold">
                      Positional sleep apnea
                    </span>{' '}
                    occurs when the majority of apneic episodes can be
                    attributed to sleep position. When you are in a supine sleep
                    position, lying flat on your back, the shape and size of
                    your upper airway are altered.
                  </li>
                </ul>
              </div>
              <div className="flex-box-item-imageOSA">
                <div className="flex-box-item-imageOSA-title emphasis bold">
                  Obstructive Sleep Apnea Illustration
                  <br />
                  <br />
                </div>
                <img
                  src="/images/sleep_apnea_diagram.png"
                  alt="obstructive sleep apnea diagram"
                  width="400px"
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-two"
            className="accordion__input"
          />
          <label htmlFor="section-two" className="accordion__label">
            What are the symptoms of sleep apnea?
          </label>
          <div className="accordion__content">
            <ul>
              <li>Excessive daytime sleepiness</li>
              <li>Observed episodes of stopped breathing during sleep</li>
              <li>Abrupt awakenings accompanied by gasping or choking</li>
              <li>Loud snoring</li>
              <li>Awakening with a dry mouth or sore throat</li>
              <li>Morning headache</li>
              <li>Difficulty concentrating during the day</li>
              <li>Mood changes, such as depression or irritability</li>
              <li>High blood pressure</li>
              <li>Decreased libido</li>
            </ul>
          </div>
        </div> */}

        {/* <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-three"
            className="accordion__input"
          />
          <label htmlFor="section-three" className="accordion__label">
            What are the mediacl impacts of sleep apnea?
          </label>
          <div className="accordion__content">
            <ul>
              <li>daytime fatigue</li>
              <li>impacted focus and critical thinking</li>
              <li>depression</li>
              <li>mood swings</li>
              <li>high blood pressure or heart problems</li>
              <li>type 2 diabetes</li>
              <li>metabolic syndrome</li>
              <li>complications with medications and surgery</li>
              <li>liver problems</li>
              <li>associated with dimentia and Alzhimer's</li>
            </ul>
          </div>
        </div> */}

        {/* <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-four"
            className="accordion__input"
          />
          <label htmlFor="section-four" className="accordion__label">
            When to see a doctor about sleep apnea
          </label>
          <div className="accordion__content">
            <ul>
              <li>
                Snoring loud enough to disturb your sleep or that of others
              </li>
              <li>Waking up gasping or choking</li>
              <li>Pausing in your breathing during sleep</li>
              <li>
                Having excessive daytime drowsiness, which may cause you to fall
                asleep while working, watching television or even driving a
                vehicle
              </li>
            </ul>
          </div>
        </div> */}

        {/* <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-five"
            className="accordion__input"
          />
          <label htmlFor="section-five" className="accordion__label">
            When to see a doctor about sleep apnea
          </label>
          <div className="accordion__content">
            <ul>
              Lifestyle changes
              <ul>
                <li>Positional Thearpay; Don't sleep on your back</li>
                <li>Lose weight if you're overweight</li>
                <li>Exercise regularly</li>
                <li>
                  Drink alcohol moderately, if at all. Don't drink in the hours
                  before bedtime
                </li>
                <li>Quit smoking.</li>
                <li>Use a nasal decongestant or allergy medications</li>
                <li>
                  Avoid taking sedative medications such as anti-anxiety drugs
                  or sleeping pills.
                </li>
              </ul>
              <br />
              Therapies
              <ul>
                <li>Positive airwy pressure (CPAP/BiPAP)</li>
                <li>Mouthpiece</li>
              </ul>
              <br />
              Surgeries
              <ul>
                <li>surgical removal of tissue (e.g. UPPP)</li>
                <li>Upper airway stimultion</li>
                <li>Jaw Surgery (e.g. MMA)</li>
                <li>urgical opening of the neck (tracheostomy)</li>
              </ul>
            </ul>
            <br />
            <div className="web-citation emphasis">
              --Mayo Clinic:{' '}
              <a
                href="https://www.mayoclinic.org/diseases-conditions/sleep-apnea/symptoms-causes/syc-20377631"
                target="blank"
              >
                https://www.mayoclinic.org/diseases-conditions/sleep-apnea/symptoms-causes/syc-20377631
              </a>
            </div>
          </div>
        </div> */}

        {/* <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-six"
            className="accordion__input"
          />
          <label htmlFor="section-six" className="accordion__label">
            Sleep apnea studies...
          </label>
          <div className="accordion__content">
            <div className="section-title">
              The undervalued potential of positional therapy in
              position-dependent snoring and obstructive sleep apnea
            </div>
            <div className="article-summary">
              Long-term compliance for PT remains an issue, and although
              remarkable results have been shown using innovative treatment
              concepts for PT, there is room for both technical improvement of
              the devices and for further research...
            </div>
            <div className="article-citation">
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3575552"
                target="blank"
              >
                National Library of Medicine
              </a>
              : M. J. L. Ravesloot, PhD; J. P. van Maanen, PhD; Dr. L. Dun, Dr.
              N. de Vries
            </div>
            <br />

            <div className="section-title">
              Positional therapy for obstructive sleep apnoea
            </div>
            <div className="article-summary">
              The review found that CPAP has a greater effect on improving AHI
              compared with positional therapy in positional OSA, while
              positional therapy was better than inactive control for improving
              ESS and AHI. Positional therapy may have better adherence than
              CPAP...
            </div>
            <div className="article-citation">
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6491901/"
                target="blank"
              >
                National Library of Medicine
              </a>
              : P R Srijithesh,corresponding author Rajeswari Aghoram, Amit
              Goel, Jayaraj Dhanya, and Cochrane Airways Group
            </div>
            <br />

            <div className="section-title">
              Usage of Positional Therapy in Adults with Obstructive Sleep Apnea
            </div>
            <div className="article-summary">
              Positional therapy is an effective method to treat patients with
              positional OSA on the short-term. Long-term compliance is low
              especially in patients with moderate OSA at baseline. More
              comfortable devices such as vibrating devices might be more useful
              to treat positional OSA...
            </div>
            <div className="article-citation">
              <a
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6491901/"
                target="blank"
              >
                National Library of Medicine
              </a>
              : Grietje E. de Vries, MSc; Aarnoud Hoekema, MD, PhD; Michiel H.J.
              Doff, DMD, PhD; Huib A.M. Kerstjens, MD, PhD; Petra M. Meijer, NP;
              Johannes H. van der Hoeven, MD, PhD; and Peter J. Wijkstra, MD,
              PhD
            </div>
            <br />
          </div>
        </div> */}

        {/* <div>
          <input
            type="checkbox"
            name="FAQ_accordion"
            id="section-seven"
            className="accordion__input"
          />
          <label htmlFor="section-seven" className="accordion__label">
            How can postitional therapy help sleep apnea?
          </label>
          <div className="accordion__content">
            Studies have shown that positional therapy can reduce positional
            sleep apnea, by opening up the airways with side sleeping.
            CPAP/BiPAP is normally the best treatment to address moderate to
            severe sleep apnea. Positional therapy can be used in conjunction
            with CPAP if it or when someone is unable to use CPAP.
          </div>
        </div> */}
      </div>
      {/* <br />
      The current positional therapy sDevice, is designed for treating general
      and snoring positional therapy. It has not yet been configured
      specifically for sleep apnea or approved by the FDA. */}
      {/* <br />
      <Link to="/LeadIn">
        <button type="button" className="button button-landing">
          <span className="button-text">Learn more about the sDevice...</span>
        </button>
      </Link> */}
    </main>
  );
}

export default ObstructiveSleepApneaFAQ;
