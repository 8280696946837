import { useState } from 'react'; // Import useState for managing state
import { Link } from 'react-router-dom';
import DonutChart from './DonutChart'; // Import the donut chart component
import '../styles/base.css';
import '../App.css';
import '../styles/WIP.css';

function WIP() {
  // Initialize sleepScore with a default value using useState
  const [sleepScore, setSleepScore] = useState(91);
  const [hrsSleep, setHrsSleep] = useState(6.7);
  const [cueResponsiveness, setCueResponsiveness] = useState(98);
  const [offBack, setoffBack] = useState(97);

  // Function to handle input changes
  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    // Check if value is a number and between 0 and 100
    if (!isNaN(value) && value >= 0 && value <= 101) {
      setSleepScore(value); // Update sleepScore state
    }
  };

  return (
    <main className="page main-content">
      <br />
      <br />
      <input
        type="number"
        id="sleepScore"
        value={sleepScore}
        onChange={handleInputChange}
        min="0"
        max="100"
      />
      <br />
      <br />
      <center>
        {/* DonutChart component displaying the dynamic sleepScore */}
        <DonutChart sleepScore={sleepScore} />
        <div className="PositionalSleepScore">Positional Sleep Score</div>
        <br />
        <br />
        <div>
          <div>
            <table className="SummaryTable">
              <tr>
                <td className="accent-color">
                  <b>{hrsSleep}</b>
                </td>
                <td>hours of sleep</td>
                <td></td>
              </tr>
              <tr>
                <td className="accent-color">
                  <b>{cueResponsiveness}</b>
                </td>
                <td>% cue responsiveness</td>
                <td>⭐️</td>
              </tr>
              <tr>
                <td className="accent-color">
                  <b>{offBack}</b>
                </td>
                <td>% off back</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td className="accent-color">
                  <a href="">full summary...</a>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </center>
    </main>
  );
}

export default WIP;
