// import { Link } from 'react-router-dom';
// import '../styles/landing.css';
import '../styles/about_us.css';

function about_us() {
  return (
    <div>
      <div className="about-stripe">
        <div className="about-stripe-container">
          <div className="about-stripe-contents">
            <div className="about-section-header">About Us</div>
            <div className="about-plus-team-block">
              <div>
                <div className="about-stripe-header">
                  We Understand the Importance of Quality Sleep
                </div>

                <div className="about-stripe-copy about-top-copy">
                  We understand the severity of sleep issues and their impact on
                  people's lives. Our founders have personally experienced the
                  negative effects of snoring, insomnia, sleep apnea, and
                  chronic sleep deprivation.
                  <br />
                  <br />
                  That's why we're committed to developing innovative solutions
                  that can help people overcome these problems and enjoy better
                  sleep and lives. By combining cutting-edge technology with
                  expert knowledge and compassionate support, we aim to make a
                  real difference in the lives of those struggling with sleep
                  issues.
                </div>
              </div>
              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Employee_Michael.jpg"
                  alt="Michael Swertfager"
                />
                <br />
                Michael Swertfager
                <br />
                Chief Executive Officer
                <br />
                &amp; co-founder
                <br />
                <a
                  href="https://www.linkedin.com/in/swertfager/"
                  target="blank"
                >
                  <img
                    className="linkedIn-icon"
                    src="/images/icons/LI-In-Bug.png"
                    alt="to Michael Swertfager's LinkedIn"
                  />
                </a>
              </div>

              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Employee_James.jpg"
                  alt="James Gartrell"
                />
                <br />
                James Gartrell
                <br />
                Chief Technology Officer
                <br />
                &amp; co-founder
                <br />
                <a
                  href="https://www.linkedin.com/in/jamesgartrell/"
                  target="blank"
                >
                  <img
                    className="linkedIn-icon"
                    src="/images/icons/LI-In-Bug.png"
                    alt="to James Gartrell's LinkedIn"
                  />
                </a>
              </div>

              {/* <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Employee_Susan.jpg"
                  alt="Susan Prohaska"
                />
                <br />
                Susan Prohaska, PhD
                <br />
                Chief Operating Officer
                <br />
                &amp; Head of Clinical Development
                <br />
                <a
                  href="https://www.linkedin.com/in/susanprohaska/"
                  target="blank"
                >
                  <img
                    className="linkedIn-icon"
                    src="/images/icons/LI-In-Bug.png"
                    alt="to Susan Prohaska's LinkedIn"
                  />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="about-stripe">
        <div className="about-stripe-container">
          <div className="about-stripe-contents">
            <div className="about-stripe-header">Integrity</div>
            <div className="about-stripe-copy">
              Our mission is to develop technology solutions to address sleep
              problems that are both effective and comfortable. By prioritizing
              user comfort and satisfaction, we seek to create solutions that
              can help people improve their sleep quality in a sustainable and
              long-lasting way.
              <br />
              <br />
              We pursue this goal through innovative approaches, hard work, and
              an unwavering focus on the technical details, while keeping in
              mind the delicate nature of restful and restorative sleep.
              Addressing sleep problems is a complex and challenging task that
              requires a deep commitment to developing quality products, rather
              than relying on marketing hype and unwarranted claims. Sleep is
              too important and nobody should compromise your sleep and well
              being just to target larger profits.
            </div>
          </div>
        </div>
      </div>

      <div className="about-stripe">
        <div className="about-stripe-container">
          <div className="about-stripe-contents">
            <div className="about-stripe-header">
              Continuous Learning and Improvement
            </div>
            <div className="about-stripe-copy">
              Sleep is a new frontier in western medicine. Due to the black box
              nature of the brain, science has only been able to get simple
              electroencephalograms (EEG’s) of the brain as it sleeps. Finding
              scientific answers on how sleep affects our cognitive abilities,
              memories, moods, brain and organ health, and general well being is
              just getting started. We recognize advances in sleep medicine
              takes continuous industry learning and technological improvement.
              A large portion of our time has been and will continue to be spent
              reviewing the latest medical publications and engaging with the
              medical community.
            </div>
          </div>
        </div>
      </div>

      <div className="about-stripe">
        <div className="about-stripe-container">
          <div className="about-stripe-contents">
            <div className="about-stripe-header">Resilience</div>
            <div className="about-stripe-copy">
              The largest factors to completing goals are grit and resilience
              when the challenges push back. SleepDev is led by a team of
              experienced and driven founders who have successfully executed
              multimillion-dollar projects with ambitious goals. With a
              persistent and tenacious spirit, we are committed to overcoming
              the challenges inherent in developing effective solutions for
              sleep problems. Our goal is to continue producing innovative
              solutions that can help people overcome their sleep challenges and
              improve their overall well-being.
            </div>
          </div>
        </div>
      </div>

      <div className="about-stripe">
        <div className="about-stripe-container">
          <div className="about-stripe-contents">
            <div className="about-section-header">Advisors</div>
            <div className="about-stripe-header">Business Advisors</div>
            <div className="about-plus-team-block">
              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Advisor_David.jpg"
                  alt="David Black"
                />
                <br />
                David Black
                <br />
                Sr VP of Finance Pavilion
                <br />
                <div className="icon_outer_container">
                  <div class="icon_LinkedIn_container"></div>
                  <div className="icon_row_container">
                    <img
                      src="/images/icons/Pavilion_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Advantel_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Forte_Networks_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Extreme_Networks_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/bea_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                  </div>
                  <div class="icon_LinkedIn_container">
                    <a
                      href="https://www.linkedin.com/in/david-black-a72b591/"
                      target="blank"
                    >
                      <img
                        className="linkedIn_icon"
                        src="/images/icons/LinkedIn_200x200.jpg"
                        alt="to David's LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/* 
              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Advisor_Susan.jpg"
                  alt="Susan Prohaska, PhD"
                />
                <br />
                Susan Prohaska, PhD
                <br />
                Cofounder, Scientist
                <br />
                <div className="icon_outer_container">
                  <div class="icon_LinkedIn_container"></div>
                  <div className="icon_row_container">
                    <img
                      src="/images/icons/Jasper_Therapeutics_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Forty_Seven_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Stanford_GSB_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Stanford_Med_onWhite_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/SRI_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                  </div>
                  <div class="icon_LinkedIn_container">
                    <a
                      href="https://www.linkedin.com/in/susanprohaska/"
                      target="blank"
                    >
                      <img
                        className="linkedIn_icon"
                        src="/images/icons/LinkedIn_200x200.jpg"
                        alt="to Susan's LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Advisor_Bret.jpg"
                  alt="Bret Sullivan, DC, DABCO"
                />
                <br />
                Bret Sullivan, DC, DABCO
                <br />
                VA Monterey
                <br />
                <div className="icon_outer_container">
                  <div className="icon_row_container">
                    <img
                      src="/images/icons/Veteran_Affairs_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Psymetrics_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/spine_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/UnitedHealthcare_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Cleveland_University_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                  </div>
                  <div class="icon_LinkedIn_container">
                    <a
                      href="https://www.linkedin.com/in/c-bret-sullivan-dc-dabco-2836826/"
                      target="blank"
                    >
                      <img
                        className="linkedIn_icon"
                        src="/images/icons/LinkedIn_200x200.jpg"
                        alt="to Bret's LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Advisor_Mark.jpg"
                  alt="Mark Williams"
                />
                <br />
                Mark Williams
                <br />
                CTO TachTech
                <br />
                <div className="icon_outer_container">
                  <div className="icon_row_container">
                    <img
                      src="/images/icons/TachTech2_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Redapt_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Zynga_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Salk_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/UCSD_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                  </div>
                  <div class="icon_LinkedIn_container">
                    <a
                      href="https://www.linkedin.com/in/m2kw6s/"
                      target="blank"
                    >
                      <img
                        className="linkedIn_icon"
                        src="/images/icons/LinkedIn_200x200.jpg"
                        alt="to Marks's LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="about-stripe-header">Clinical Advisors</div>
            <div className="about-plus-team-block">
              {/* START */}
              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Advisor_Daniel2.jpg"
                  alt="Daniel Cher"
                />
                <br />
                Daniel Cher, MD
                <br />
                Sr. VP of Clinical & Regulatory Affairs, SI-BONE
                <br />
                Doctor of Medicine, Yale
                <br />
                <div className="icon_outer_container">
                  <div class="icon_LinkedIn_container"></div>
                  <div className="icon_row_container">
                    <img
                      src="/images/icons/SI-Bone_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/WildOrchid_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/PROCEPT_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/YSM_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Stanford_University_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                  </div>
                  <div class="icon_LinkedIn_container">
                    <a
                      href="https://www.linkedin.com/in/danielcher/"
                      target="blank"
                    >
                      <img
                        className="linkedIn_icon"
                        src="/images/icons/LinkedIn_200x200.jpg"
                        alt="to Daniels' LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Advisor_Aditya2.jpg"
                  alt="Aditya"
                />
                <br />
                Aditya Chandrasekhar, MD MPH FACP CPE
                <br />
                Primary Care Physician, Fenway Health
                <br />
                Medical Director, Primary Health
                <br />
                <div className="icon_outer_container">
                  <div class="icon_LinkedIn_container"></div>
                  <div className="icon_row_container">
                    <img
                      src="/images/icons/Fenway_Health_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Mass_Hospital_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Beth_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Grant_Medical_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Harvard_Medical_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                  </div>
                  <div class="icon_LinkedIn_container">
                    <a
                      href="https://www.linkedin.com/in/aditya-c-17b752231/"
                      target="blank"
                    >
                      <img
                        className="linkedIn_icon"
                        src="/images/icons/LinkedIn_200x200.jpg"
                        alt="to Aditya's LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/* END */}

              {/* <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Advisor_Anil.jpg"
                  alt="Anil Rama, MD"
                />
                <br />
                Anil Rama, MD Sleep Medicine
                <br />
                Founder of Sleep and Brain
                <br />
                <div className="icon_outer_container">
                  <div className="icon_row_container">
                    <img
                      src="/images/icons/Sleep_and_Brain_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Stanford_Med_onWhite_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Kaiser_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/George_Washington_School_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/UCBerkeley_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                  </div>
                  <div class="icon_LinkedIn_container">
                    <a
                      href="https://www.linkedin.com/in/anil-rama-md-6277a3166/"
                      target="blank"
                    >
                      <img
                        className="linkedIn_icon"
                        src="/images/icons/LinkedIn_200x200.jpg"
                        alt="to Anil's LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </div> */}

              <div className="team-member-block">
                <img
                  className="team-image"
                  src="/images/team/Advisor_Logan.jpg"
                  alt="Logan Schneider, MD"
                />
                <br />
                Logan Schneider, MD
                <br />
                Neurologist - Stanford/VA Alzheimer’s Research Center
                <br />
                Sleep Medicine - Adjunct Professor Stanford
                <br />
                <div className="icon_outer_container">
                  <div className="icon_row_container">
                    <img
                      src="/images/icons/VA_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Stanford_Med_onWhite_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Harbor_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Google_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                    <img
                      src="/images/icons/Johns_Hopkins_200x200.jpg"
                      alt="placeholder_200x200.jpg"
                      className="icon"
                    />
                  </div>
                  <div class="icon_LinkedIn_container">
                    <a
                      href="https://www.linkedin.com/in/loganschneider/"
                      target="blank"
                    >
                      <img
                        className="linkedIn_icon"
                        src="/images/icons/LinkedIn_200x200.jpg"
                        alt="to Logan's LinkedIn"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // <main className="page main-content">Inside AboutUs</main>;
}

export default about_us;
