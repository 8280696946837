import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/executivesummary.css';
import '../styles/FAQ.css';

function executiveSummaryConfidential() {
  return (
    <main className="page main-content executive-summary">
      <center>
        <div className="page-title">Executive Summary</div>
        {/* <div className="page-secondary-title">
          <span className="accent_primary_color_only">Objective:</span> Reduce
          mortality rates for millions of OSA sufferers
        </div> */}
        <div className="page-secondary-title">
          <span className="accent_primary_color_only">Objective: </span>
          Alleviate the suffering for millions of people with obstructive sleep
          apnea (OSA)
        </div>
      </center>
      {/* <hr className="faded" /> */}
      <br />
      <div className="flex-box-container">
        <section className="flex-box-item flex-box-item-1">
          <div>
            <table>
              <tr>
                <td valign="top" className="accent_primary">
                  Problem
                </td>
                <td valign="top">
                  <ul>
                    <li>936 million people worldwide suffer from OSA</li>
                    <li>
                      Untreated OSA is linked to heart disease, dementia, liver
                      disease, sleep deprivation, and depression
                    </li>
                    <li>
                      After 3.5 years, more than half abandon CPAP treatment,
                      driving the search for alternatives
                      <br />
                      <span className="citation">
                        &nbsp;(
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8745469/"
                          className="App-link-color"
                          target="blank"
                        >
                          J Clin Med. 2022, Factors Affecting Long-Term
                          Compliance of CPAP
                        </a>
                        )
                      </span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span>
                    <hr className="heavily_faded" />
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top" className="accent_primary">
                  Solution
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      WhisperPT-Ear employs positional therapy (PT) to treat
                      obstructive sleep apnea by using sound cues to encourage
                      side sleeping
                    </li>
                    <li>
                      56% - 75% of OSA patients have significant reduction of
                      obstructions using PT
                      <br />
                      <span className="citation">
                        &nbsp;(
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8745469/"
                          className="App-link-color"
                          target="blank"
                        >
                          M. J. L. Ravesloot 2012, The Undervalued potential of
                          positional therapy.
                        </a>
                        )
                      </span>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span>
                    <hr className="heavily_faded" />
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top" className="accent_primary">
                  Market Analysis
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      $18B - (TAM) by 2032. 100M people currently diagnosed with
                      OSA
                      <br />
                      <span className="citation">
                        <a
                          href="https://www.marketwatch.com/press-release/23-1-billion-sleep-apnea-devices-market-industry-trends-and-global-forecasts-2024-2035-researchandmarkets-com-f3685012"
                          className="App-link-color"
                          target="blank"
                        >
                          (Fortune Business Insights - "Sleep Apnea Device
                          Market Size...")
                        </a>
                      </span>
                    </li>
                    <li>
                      $11B - (SAM) by 2030, 63M people currently diagnosed with
                      OSA and positionally influenced
                    </li>
                    <li>
                      $100M - bottoms-up forecasted revenues across 6 years
                      (SOM)
                    </li>
                    <li>
                      The main competing positional OSA product, Philips' Night
                      Balance, has been discontinued as a larger fallout of
                      Philips' CPAP FDA consent decree. Opening the market even
                      more for us.
                      <br />
                      <span className="citation">
                        &nbsp;(
                        <a
                          href="https://sleepreviewmag.com/sleep-treatments/therapy-devices/cpap-pap-devices/sleep-therapy-sector-responding-philips-respironics-exit/"
                          className="App-link-color"
                          target="blank"
                        >
                          Sleep Review 2024, ...Sleep Therapy Sector Is
                          Responding to Philips' Exit
                        </a>
                        )
                      </span>
                    </li>
                    <li>
                      Secondary competitors include positional therapy
                      backpacks/pillows, CPAPs, and dental devices: which are
                      either invasive or cumbersome{' '}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span>
                    <hr className="heavily_faded" />
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top" className="accent_primary">
                  Traction
                </td>
                <td valign="top">
                  <ul>
                    <li>Completed: Minimum Viable Product (device and app)</li>
                    <li>Completed: 11 months of sleep testing</li>
                    <li>
                      Over 3 years of iterative development and testing on the
                      foundational PT device technology and code
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span>
                    <hr className="heavily_faded" />
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top" className="accent_primary">
                  Intellectual Property
                </td>
                <td valign="top">
                  <ul>
                    <li>Patentable with clear freedom to operate</li>
                    <li>Four patents filed</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span>
                    <hr className="heavily_faded" />
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top" className="accent_primary">
                  Regulatory
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      Pre-assessment indicates clear path to 510(k) clearance
                      using 2 predicates
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <span>
                    <hr className="heavily_faded" />
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top" className="accent_primary">
                  Team
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/swertfager/"
                        target="blank"
                      >
                        <span className="App-link-color">
                          Michael Swertfager:
                        </span>
                      </a>
                      &nbsp; CEO &amp; co-founder; former Apple PMO & Sr Program
                      Manager
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/jamesgartrell/"
                        target="blank"
                      >
                        <span className="App-link-color">James Gartrell:</span>
                      </a>
                      &nbsp; CTO &amp; co-founder; former Riot Games Technical
                      Director
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <span>
                    <hr className="heavily_faded" />
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top" className="accent_primary">
                  Clinical Advisors
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/loganschneider/"
                        target="blank"
                      >
                        <span className="App-link-color">
                          Logan Schneider, MD:
                        </span>
                      </a>
                      &nbsp;Sleep Medicine & Neurologist, Johns Hopkins;
                      Stanford
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/aditya-c-17b752231/"
                        target="blank"
                      >
                        <span className="App-link-color">
                          Aditya Chandrasekar, MD, MPH, FACP, CPE:
                        </span>
                      </a>
                      &nbsp; Medical Director at Primary Health; Grant Medical
                      College; Johns Hopkins
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/danielcher/"
                        target="blank"
                      >
                        <span className="App-link-color">Daniel Cher, MD:</span>
                      </a>
                      &nbsp; Sr. VP of Clinical & Regulatory Affairs at SI-BONE;
                      Stanford; Yale
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <span>
                    <hr className="heavily_faded" />
                  </span>
                </td>
              </tr>

              <tr>
                <td valign="top" className="accent_primary">
                  $2M Offer
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      Seeking $2M early-stage funding
                      <ul>
                        <li>Post-money SAFE</li>
                        <li>20% discount / $10M Cap</li>
                        <li>$8M pre-money valuation</li>
                      </ul>
                    </li>
                    <li>
                      To support:{' '}
                      <ul>
                        <li>MVP productization</li>
                        <li>Initial sales traction</li>
                        <li>FDA 510(k) clearance</li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
        </section>
        {/* Source 3*/}
        <section className="flex-box-margin-only">
          <table>
            <tr>
              <td valign="top">
                WhisperPT-Ear
                <br />
                Minimal Viable Product
                <div className="boxed">
                  <div className="image-padding">
                    <center>
                      <img
                        className="hero-image"
                        src="/images/product/WPT-Ear_V3_in_ear_close-up_and_measurement.jpg"
                        alt="WPT-Ear_V3"
                        width="300px"
                      />
                    </center>
                  </div>
                </div>
              </td>
            </tr>
            <br />
            <tr>
              <td>
                Integrated App
                <div className="boxed">
                  <div className="image-padding">
                    <center>
                      <img
                        className="hero-image"
                        // src="/images/product/App_Screen_Shots.jpg"
                        src="/images/product/App_Configure_and_Summary2.jpg"
                        alt="WPT-PAP Low Profile and High Fidelity"
                        width="300px"
                      />
                      <br />
                    </center>
                  </div>
                </div>
              </td>
            </tr>
            <br />
            {/* <div className="accent_primary">Testimonials</div> */}
            <br />
            <div className="narrow-quote-block">
              "This technology is a game changer. The device is a great
              alternative to traditional sleep apnea treatments."
              <br />
              <span className="accent_standard">
                Dr. Anil Rama
                <br />
                Sleep Medicine - Stanford
              </span>
            </div>
            <br />
            <div className="narrow-quote-block">
              "I expect the future of OSA management to focus on positional
              therapy."
              <br />
              <span className="accent_standard">
                Dr. Bryan Chessen
                <br /> Sleep Medicine - NYC Health
              </span>
            </div>
            <br />
            <div className="narrow-quote-block">
              "Sold. When can I prescribe this?"
              <br />
              <span className="accent_standard">
                Dr. Sam Kashani
                <br /> Sleep Medicine - UCLA
              </span>
            </div>
          </table>
        </section>
      </div>
      <div>
        <hr className="heavily_faded" />
      </div>
      <br />
      <center>
        <div className="align-left">
          <table className="bottom">
            <tr>
              <td cellPadding="20px">
                <div className="">Additional Resources:</div>
                <ul>
                  <li>
                    <a
                      href="/docs/SleepDev_PitchDeck.pdf"
                      className="App-link-color"
                      target="blank"
                    >
                      SleepDev pitch deck
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://vimeo.com/manage/videos/958224879"
                      className="App-link-color"
                      target="blank"
                    >
                      Recorded SleepDev short pitch (6 mins)
                    </a>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                {' '}
                <div className="">
                  Strategic partners and investors can engage Michael
                  Swertfager, CEO & co-founder, through:
                  <br />
                </div>
                <a
                  href="mailto:michael@SleepDev.com"
                  className="App-link-color"
                >
                  <span className="App-link-color">michael@SleepDev.com</span>
                </a>
                &nbsp; / &nbsp; (408)-499-6884 /
                <a
                  href="https://calendly.com/mswertfager-sleepdev"
                  className="App-link-color"
                  target="_blank"
                >
                  {' '}
                  quick discussion - calendly
                </a>
              </td>
            </tr>
            {/* <tr>
              <td>SleepDev is a registered Delaware C Corporation </td>
            </tr> */}
          </table>
        </div>
      </center>
    </main>
  );
}
export default executiveSummaryConfidential;
